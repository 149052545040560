var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-form",
        {
          ref: "updateAlbumRequest",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.updateAlbumRequest,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { border: "1px solid rgb(96 99 102 / 19%)" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại album" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { height: "10px" },
                          model: {
                            value: _vm.updateAlbumRequest.idClass,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateAlbumRequest, "idClass", $$v)
                            },
                            expression: "updateAlbumRequest.idClass",
                          },
                        },
                        _vm._l(_vm.listClass, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.className, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-60px",
                        "margin-right": "100px",
                      },
                      attrs: { label: "Tên Album", prop: "albumName" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập tiêu đề..." },
                        model: {
                          value: _vm.updateAlbumRequest.albumName,
                          callback: function ($$v) {
                            _vm.$set(_vm.updateAlbumRequest, "albumName", $$v)
                          },
                          expression: "updateAlbumRequest.albumName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { clear: "both" },
                      attrs: { label: "Mô tả Album", prop: "desc" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "Nhập nội dung...",
                          rows: 2,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.updateAlbumRequest.albumDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updateAlbumRequest,
                              "albumDescription",
                              $$v
                            )
                          },
                          expression: "updateAlbumRequest.albumDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "max-height": "492px", overflow: "scroll" } },
                [
                  _c(
                    "h3",
                    {
                      staticStyle: {
                        "margin-left": "35px",
                        "margin-bottom": "20px",
                        "margin-top": "-39px",
                      },
                    },
                    [_vm._v("\n          Ảnh được chọn\n        ")]
                  ),
                  _vm.showProgress
                    ? _c("el-progress", {
                        staticStyle: {
                          width: "25%",
                          float: "left",
                          "margin-top": "23px",
                        },
                        attrs: {
                          percentage: _vm.percentage,
                          color: _vm.colors,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticStyle: { clear: "both" },
                      attrs: {
                        action: "#",
                        "list-type": "picture-card",
                        "auto-upload": false,
                        "file-list": _vm.fileList,
                        "on-change": _vm.handleChangePicture,
                        "on-remove": _vm.handleRemove,
                        multiple: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function ({ file }) {
                            return _c("div", {}, [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: { src: file.url, alt: "" },
                              }),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePictureCardPreview(
                                            file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in",
                                      }),
                                    ]
                                  ),
                                  !_vm.disabled
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "el-icon-plus",
                          staticStyle: { "font-style": "normal" },
                          attrs: { slot: "default" },
                          slot: "default",
                        },
                        [_vm._v("Thêm Ảnh")]
                      ),
                    ]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: { width: "30%", visible: _vm.dialogVisible },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c("h3", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(
                      "\n          Danh sách ảnh album: " +
                        _vm._s(this.updateAlbumRequest.albumName) +
                        "\n        "
                    ),
                  ]),
                  _c("hr", {
                    staticStyle: { width: "98%", "margin-top": "-18px" },
                  }),
                  Array.isArray(_vm.updateAlbumRequest.alistPictureList) &&
                  _vm.updateAlbumRequest.alistPictureList.length
                    ? _c(
                        "el-row",
                        {
                          staticClass: "demo-image__placeholder",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                        },
                        [
                          _vm._l(
                            _vm.updateAlbumRequest.alistPictureList,
                            function (listPicture, index) {
                              return _c(
                                "div",
                                {
                                  key: listPicture,
                                  staticClass: "inline-block",
                                  staticStyle: {
                                    float: "left",
                                    margin: "14px",
                                    border: "1px solid #dcdfe6",
                                    width: "160px",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Xem ảnh",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "100%",
                                          height: "160px",
                                        },
                                        attrs: {
                                          src: listPicture.urlShowPicture,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleShowDetailPicture(
                                              index,
                                              listPicture
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.checkPermission([
                                    "studentQuality_ablum_update",
                                  ])
                                    ? _c(
                                        "span",
                                        [
                                          listPicture.isApproved
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    width: "65%",
                                                    color: "white",
                                                    "font-size": "15px",
                                                    "text-align": "left",
                                                    float: "left",
                                                  },
                                                  attrs: { type: "success" },
                                                },
                                                [_vm._v("Đã duyệt")]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    width: "65%",
                                                    color: "white",
                                                    "font-size": "15px",
                                                    "text-align": "left",
                                                    float: "left",
                                                  },
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateApprovePicture(
                                                        listPicture.id,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Chưa duyệt")]
                                              ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-actions",
                                            },
                                            [
                                              listPicture.isApproved
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "el-upload-list__item-preview",
                                                      staticStyle: {
                                                        float: "left",
                                                        height: "40.5px",
                                                      },
                                                      attrs: {
                                                        type: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deletePicture(
                                                            listPicture.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete-solid",
                                                        staticStyle: {
                                                          color: "white",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "el-upload-list__item-preview",
                                                      staticStyle: {
                                                        float: "left",
                                                        height: "40.5px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deletePicture(
                                                            listPicture.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete-solid",
                                                        staticStyle: {
                                                          color: "white",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div"),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                width: "50%",
                                visible: _vm.dialogVisiblePic,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisiblePic = $event
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "image-container" }, [
                                _c("img", {
                                  staticClass: "centered-image",
                                  attrs: { src: _vm.selectedImg, alt: "" },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "button-container" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-d-arrow-left",
                                        },
                                        on: { click: _vm.backPage },
                                      },
                                      [_vm._v("Trước")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "success" },
                                        on: { click: _vm.setUpAvatarAlbum },
                                      },
                                      [_vm._v("Đặt làm ảnh đại diện")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-d-arrow-right",
                                        },
                                        on: { click: _vm.nextPage },
                                      },
                                      [_vm._v("Sau")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticStyle: { "margin-top": "20px", float: "left" } }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Người đăng :"),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.updateAlbumRequest.createdBy) +
                  " |\n        " +
                  _vm._s(_vm.updateAlbumRequest.pictureApprovedNumber) +
                  "/" +
                  _vm._s(_vm.updateAlbumRequest.pictureNumber) +
                  "\n        ảnh | " +
                  _vm._s(
                    _vm._f("formatDate")(_vm.updateAlbumRequest.createdDate)
                  )
              ),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", float: "right" } },
            [
              _vm.checkPermission(["studentQuality_ablum_update"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "button-over",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.submitUpload("updateAlbumRequest")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _vm._v("\n        Lưu thay đổi\n      "),
                    ]
                  )
                : _vm._e(),
              _vm.checkPermission(["studentQuality_ablum_update"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "button-over",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.updateAllApprovePicture(
                            _vm.updateAlbumRequest.id
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v("\n        Duyệt tất cả\n      "),
                    ]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/student-quality/album" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button-over",
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.createEmployeeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-back" }),
                      _vm._v("\n          Quay lại\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }